import pimg1 from '../images/project/img-1.jpg'
import pimg2 from '../images/project/img-2.jpg'
import pimg3 from '../images/project/img-3.jpg'
import pimg4 from '../images/project/img-4.jpg'
import pimg5 from '../images/project/img-5.jpg'
import pimg6 from '../images/project/img-6.jpg'
import pimg7 from '../images/project/img-7.jpg'

import ps1img1 from '../images/project-single/img-1.jpg'
import ps1img2 from '../images/project-single/img-2.jpg'
import ps1img3 from '../images/project-single/img-3.jpg'
import ps1img4 from '../images/project-single/img-4.jpg'
import ps1img5 from '../images/project-single/img-5.jpg'
import ps1img6 from '../images/project-single/img-6.jpg'
import ps1img7 from '../images/project-single/img-7.jpg'

import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'


const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       ps1img:ps1img1,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'Architecture & Interior Website Design',  
       subTitle:'Architecture',  
       description:'Sagittis volutpat commodo suspendisse sed in lorem felis eget massa. Risus magna suspendisse sedleo matt bortr malesuada.' 
    },
    {
       Id:'2',
       pImg:pimg2,
       ps1img:ps1img2,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'Furniture and Decor Mobile App Design', 
       subTitle:'Web Design',  
       description:'Sagittis volutpat commodo suspendisse sed in lorem felis eget massa. Risus magna suspendisse sedleo matt bortr malesuada.'  
    },
    {
      Id:'3',
      pImg:pimg3,
      ps1img:ps1img3,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'Finance Dashboard Design',  
      subTitle:'Website / Creative',  
      description:'Sagittis volutpat commodo suspendisse sed in lorem felis eget massa. Risus magna suspendisse sedleo matt bortr malesuada.' 
   },
   {
      Id:'4',
      pImg:pimg4,
      ps1img:ps1img4,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'App Design With Maintaining UI/UX Process.', 
      subTitle:'Web Design',    
   },
   {
      Id:'5',
      pImg:pimg5,
      ps1img:ps1img5,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'Architecture & Interior Website Design', 
      subTitle:'Website / Creative',    
   },
   {
      Id:'6',
      pImg:pimg6,
      ps1img:ps1img6,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'Restaurant and Food App Design.', 
      subTitle:'App Design',    
   },
   {
      Id:'7',
      pImg:pimg7,
      ps1img:ps1img7,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'Architecture & Interior Website Design', 
      subTitle:'Web Design',    
   },
   {
      Id:'8',
      pImg:pimg5,
      ps1img:ps1img5,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'App Design and Development Process', 
      subTitle:'Web Design',    
   },
    
]

export default Projects;